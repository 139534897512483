import { ChangeDetectionStrategy, Component, Input, NgZone } from '@angular/core';
import { ApplicationStore } from '../application/application-store.service';
import { ContractService } from '../application/contract.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TakeUntil } from '../helpers/take-until.component';
import { switchMap, take } from 'rxjs';
import { StickyService } from '../sticky.service';
import { ThemingService } from '../theming/theming.service';
import { ThemingQueryParams } from '../theming/theming.model';
import { AuthenticationService } from '../application/authentication.service';
import { LegalNoticeService } from '../legal-notice/legal-notice.service';
import { CaptchaService } from './captcha.service';

@Component({
  selector: 'fol-init',
  templateUrl: './init.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InitComponent extends TakeUntil {
  @Input()
  initNumber!: string;

  captchaChecked = false;

  constructor(
    private readonly captchaService: CaptchaService,
    private readonly contractService: ContractService,
    private readonly applicationStore: ApplicationStore,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly ngZone: NgZone,
    private readonly stickyService: StickyService,
    private readonly themingService: ThemingService,
    private readonly authenticationService: AuthenticationService,
    private readonly legalNoticeService: LegalNoticeService
  ) {
    super();
    this.stickyService.clearStickyContent();
  }

  isChecked(isChecked: boolean): void {
    this.captchaChecked = true;
    if (isChecked) {
      const token = this.activatedRoute.snapshot.queryParamMap.get('token') || '';
      this.authenticationService
        .authenticate(this.initNumber, token)
        .pipe(
          take(1),
          switchMap(isAuthenticated => {
            if (isAuthenticated) {
              return this.captchaService.isValidated(isChecked).pipe(
                take(1),
                switchMap(() => {
                  return this.contractService.getContract(this.initNumber, token).pipe(
                    take(1),
                    switchMap(contract => {
                      this.applicationStore.reset(contract);
                      this.legalNoticeService.setType('personal-data');
                      return this.themingService.getThemingJson(
                        new ThemingQueryParams(contract.pointOfSale || { societyCode: 700 })
                      );
                    })
                  );
                })
              );
            } else {
              return this.themingService.getThemingJson(new ThemingQueryParams({ societyCode: 700 }));
            }
          })
        )
        .subscribe(() => {
          this.ngZone.run(() => this.router.navigateByUrl('/step'));
        });
    }
  }
}
