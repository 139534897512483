import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { urlJoin } from '../helpers/url.helper';
import { Observable } from 'rxjs';
import { TakeUntil } from '../helpers/take-until.component';
import { AppConfig } from '../../app.config';

@Injectable({
  providedIn: 'root'
})
export class CaptchaService extends TakeUntil {
  private readonly url = urlJoin(this.appConfig.baseWsURL(), 'captcha/validate');

  constructor(private readonly httpClient: HttpClient, private readonly appConfig: AppConfig) {
    super();
  }

  isValidated(validated: boolean): Observable<HttpResponse<void>> {
    return this.httpClient.post<void>(this.url, { validated }, { observe: 'response' });
  }
}
