<div class='container text-style' *ngIf='state$ | async | lowercase as activeStep'>

  <ng-container *ngIf="!isSupportingDocumentsState || (dataTransmission$ | async) as decision;else waitingScreen">
    <div class='row center text-center space-top-2 ' *ngIf="activeStep === 'application'">
      <span class='quit reminder'>{{ 'stepper.application.text' | translate }}</span>
    </div>
    <div class='title-container row center space-top-2' [ngClass]='{ signature: isSignatureState }'>
      <!-- title block -->
      <h1 *ngIf='!isSignatureState; else _signature'>{{ 'stepper.' + activeStep + (isSupportingDocumentsState ? '.' + decision : '')  + '.title' | translate }}</h1>
      <ng-template #_signature>
        <mat-icon>check</mat-icon>
        <h1>{{ 'stepper.' + activeStep + '.title' | translate }}</h1>
      </ng-template>
      <!-- title block -->
    </div>

    <div class="space-top-4">
      <div class='margin-4'  *ngIf="(isApplicationState || isSupportingDocumentsState) && decision==='r'">
        <span class='quit'>{{ 'stepper.supporting_documents.r.trust' | translate }}</span>
      </div>

      <div class='margin-4' *ngIf='isApplicationState || isSupportingDocumentsState'>
        <span class='quit' *ngIf="activeStep !== 'application'">{{ 'stepper.' + activeStep + (isSupportingDocumentsState ? '.' + decision : '') + '.text' | translate }}</span>
      </div>

      <div class='margin-4' [ngClass]='{ reverse: isFinalState }'>
        <div class="display-flex">
          <p>{{ 'stepper.' + activeStep + (isSupportingDocumentsState ? '.' + decision : '') + '.subtitle' | translate }}</p>
          <ml-popin-trigger [title]="'stepper.supporting_documents.r.popin-title' | translate" [buttonText]="'stepper.supporting_documents.r.popin-close' | translate" *ngIf="isSupportingDocumentsState && decision === 'r'">
            <ml-icon trigger iconName="info"></ml-icon>
            <p>{{ 'stepper.supporting_documents.r.information-link' | translate }}&nbsp;<a rel="noopener" href="https://asset.mediahub.bnpparibas/is/content/bnpparibas/DPN%20V3%20PF%20sans%20option%20clause%2008042022%20PF%2017052022" target="_blank">{{ 'stepper.supporting_documents.r.notice-information' | translate }}</a>&nbsp;{{ 'stepper.supporting_documents.r.information' | translate }}</p>
          </ml-popin-trigger>
        </div>
        <div class='column center step-wrapper space-top-1 gap-1' *ngIf="!(isSupportingDocumentsState && decision === RED_LIGHT.toLowerCase())">
          <fol-step *ngFor='let step of steps; let i = index' [num]='i + 1'
                      [title]='"stepper.steps." + step.lowercase | translate'
                      [validated]='validatedSteps.includes(step.enumValue)'
                    [active]='step.lowercase === activeStep'/>
        </div>
      </div>
    </div>

    <fol-summary *ngIf='isSignatureState'/>


  </ng-container>
  <ng-template #waitingScreen>
    <ml-loader [title]="'stepper.supporting_documents.waiting_screen.title' | translate" [subtitle]="'stepper.supporting_documents.waiting_screen.subtitle' | translate"></ml-loader>
  </ng-template>
  <ng-template #stickyButtonWrapper>
    <div class="justify-sticky-buttons-center">
      <a mat-flat-button color='primary' [routerLink]="['../application/alone-co-holder-choice']" class="do-not-display-as-a-link"
         *ngIf='isApplicationState'>{{ 'stepper.buttons.'  + activeStep | translate }}</a>
      <div class='column gap-1' *ngIf='isSignatureState'>
        <button mat-flat-button color='primary'>{{ 'stepper.buttons.' + activeStep + '.e-sign' | translate }}</button>
        <button mat-stroked-button color='primary'>{{ 'stepper.buttons.' + activeStep + '.dealership' | translate }}</button>
      </div>
    </div>
  </ng-template>
</div>
