import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'fol-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CaptchaComponent {
  @Output()
  isCheck = new EventEmitter<boolean>();
  checked(): void {
    this.isCheck.emit(true);
  }
}
