import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TakeUntil } from 'ngx-mobilite-material';
import { LegalNoticeResponse } from '../legal-notice.model.component';
import { Observable } from 'rxjs';
import { LegalNoticeService } from '../legal-notice.service';
import { Service } from '../../application/application.model';
import { projectToLegalNoticeInsurancesRequest } from '../legal-notice.mapper.component';
import { ApplicationStore } from '../../application/application-store.service';

@Component({
  selector: 'fol-legal-notice-insurance',
  templateUrl: './legal-notice-insurance.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegalNoticeInsuranceComponent extends TakeUntil implements OnInit {
  @Input({ required: false }) insurance: Service | undefined;
  insuranceLegalNotice$: Observable<LegalNoticeResponse> | undefined;
  constructor(private readonly legalNoticeService: LegalNoticeService, private applicationStore: ApplicationStore) {
    super();
  }

  ngOnInit(): void {
    if (this.insurance) {
      this.insuranceLegalNotice$ = this.legalNoticeService.fetchLegalNoticeInsurances(
        projectToLegalNoticeInsurancesRequest(
          this.applicationStore.instant('project')?.product?.code || '',
          this.insurance.category || '',
          this.insurance.option || ''
        )
      );
    }
  }
}
