import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { ImgComponent } from './atom/img/img.component';
import { SharedModule } from '../shared/shared.module';
import { FormModule } from '../core/helpers/form/form.module';
import { StepComponent } from './atom/step/step.component';
import { AdvancementComponent } from './advancement/advancement.component';
import { FormHeaderComponent } from './atom/form-header/form-header.component';
import { BreadcrumbTrailComponent } from './atom/breadcrumb-trail/breadcrumb-trail.component';
import { DetailRowComponent } from './atom/detail-row/detail-row.component';
import {
  IconComponent,
  LoaderComponent,
  MobilePhoneInputComponent,
  PopinTriggerComponent
} from 'ngx-mobilite-material';
import { CaptchaComponent } from './captcha/captcha.component';

@NgModule({
  declarations: [
    HeaderComponent,
    ImgComponent,
    StepComponent,
    AdvancementComponent,
    FormHeaderComponent,
    BreadcrumbTrailComponent,
    DetailRowComponent,
    CaptchaComponent
  ],
  exports: [
    HeaderComponent,
    StepComponent,
    AdvancementComponent,
    FormHeaderComponent,
    BreadcrumbTrailComponent,
    DetailRowComponent,
    LoaderComponent,
    IconComponent,
    CaptchaComponent,
    PopinTriggerComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormModule,
    IconComponent,
    LoaderComponent,
    NgOptimizedImage,
    MobilePhoneInputComponent,
    PopinTriggerComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class GraphicModule {}
